/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress as MuiCircularProgress,
  withStyles,
  Zoom,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '../../../../Components/button';
import styles from './index.module.css';

const useStyles = makeStyles({
  leftAlignDialogActions: {
    justifyContent: 'center'
  }
});
export const InfoModal = ({
  userIamChanging,
  continueButton,
  isVisible,
  onHide,
  title,
  content,
  pathName,
  fromPayment,
  processingInfo,
  isDelete,
  action,
  actionButton
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const goToContent = () => {
    localStorage.setItem('isFromUpload', true);
    navigate(pathName);
  };
  const CircularProgress = withStyles({
    circle: {
      color: '#5aa5ef !important'
    }
  })(MuiCircularProgress);
  return (
    <>
      <Dialog
        open={isVisible}
        TransitionComponent={Zoom}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <div className={styles.dialogContainer}>
          <CloseIcon className={styles.closeButton} onClick={onHide} />
          <DialogTitle>
            <div className={styles.title}>
              {title}
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {
                content ? (
                  <div className={styles.content}>
                    {content}
                  </div>
                ) : null
              }
            </DialogContentText>
          </DialogContent>
          {userIamChanging ? (
          <div style={{ display: 'flex', marginBottom: '60px' }}>
          <CircularProgress />
          </div>
) : (
  <DialogActions classes={{ root: classes.leftAlignDialogActions }}>
    {fromPayment ? (
      <div className={styles.actionContainer}>
        <Button
          size="small"
          className={styles.cancelButton}
          color="primary"
          onClick={() => {
            action();
          }}
        >
          {actionButton}
        </Button>
        {isDelete ? (
          <Button
            size="small"
            className={styles.actionButton}
            color="primary"
            onClick={() => {
              isDelete
                ? onHide()
                : processingInfo
                ? goToContent()
                : navigate(pathName);
            }}
          >
            {continueButton}
          </Button>
        ) : null}
      </div>
    ) : (
      <div className={styles.actionContainer}>
        <Button
          size="small"
          className={styles.cancelButton}
          color="primary"
          onClick={() => {
            isDelete
              ? onHide()
              : processingInfo
              ? goToContent()
              : navigate(pathName);
          }}
        >
          {continueButton}
        </Button>
        {isDelete ? (
          <Button
            size="small"
            className={styles.actionButton}
            color="primary"
            onClick={() => {
              action();
            }}
          >
            {actionButton}
          </Button>
        ) : null}
      </div>
    )}
  </DialogActions>
)}

        </div>
      </Dialog>
    </>
  );
};
