import React, { useState, useEffect, useContext } from 'react';
import {
  DialogContent, LinearProgress, makeStyles, withStyles, CircularProgress
} from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { dataFetcher, endpoints } from 'Api';
import useSWR from 'swr';
import cn from 'classnames';
import { AppContext } from 'Context';
import { ContentBox, ContentBoxSection } from '../components/ContentBox';
import { DialogFooter } from '../components/Material-reDesign';
import PlaylistInput from '../components/PlaylistInput';
import CategoryInput from '../components/CategoryInput';
import SubCategoryInput from '../components/SubCategoryInput';
import { Button } from '../../button';

import styles from '../index.module.css';
import { EditSaveToDraftButton, SaveToDraftButton } from '../components/VideoDetails';

const useStyles = makeStyles({
  colorPrimary: {
    background: '#5aa5ef'
  }
});
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);
export default function UpdateCategoryPlaylist(
  { buttonDisabled, uploadedData, moveToNextStep, currentStep, uploadChecking, moveToPreviousStep, setUploadedData, videoID, uploadText, commonText, conversionStart, handleSaveToDraft, draftApiStatus, editFlow, videoTitle, defaultVideoThumbnail, videoVisibility, updateVisibility }
) {
  const { setAppSnackbar, pubLoader } = useContext(AppContext);
  const [videoCategory, setVideoCategory] = useState(uploadedData?.category_id);
  const [videoCategoryName, setVideoCategoryName] = useState(uploadedData?.category_name);
  const [videoSubCategory, setVideoSubCategory] = useState(uploadedData?.subcategory_id);
  const [videoSubCategoryName, setVideoSubCategoryName] = useState(uploadedData?.subcategory_name);
  const [videoPlaylists, setVideoPlaylists] = useState(uploadedData?.playlist_id || []);
  const [videoPlaylistsNames, setVideoPlaylistsNames] = useState([]);
  const [videoCategoryPlaylistData, setVideoCategoryPlaylistData] = useState(undefined);
  const classes = useStyles();
  const { isValidating: uploadingCategoryPlaylist, mutate: updateCategoryPlaylistContent } = useSWR([
    endpoints.addVideoContent, videoCategoryPlaylistData,
  ], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success }) => {
      if (success) {
        moveToNextStep();
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
  localStorage.setItem('category_id', videoCategory);
  localStorage.setItem('category_name', videoCategoryName);
  localStorage.setItem('subcategory_id', videoSubCategory);
  if (Array.isArray(videoPlaylists)) {
    localStorage.setItem('playlist_id', videoPlaylists?.join(','));
  } else {
    localStorage.setItem('playlist_id', videoPlaylists);
  }
  useEffect(() => {
    setUploadedData({
      ...uploadedData,
      category_id: videoCategory,
      category_name: videoCategoryName,
      subcategory_id: videoSubCategory,
      subcategory_name: videoSubCategoryName,
      playlist_id: Array.isArray(videoPlaylists) ? videoPlaylists?.join(',') : videoPlaylists
    });
  }, [videoCategory, videoCategoryName, videoSubCategory, videoPlaylists]);

  const updateCategoryPlaylist = () => {
    const formData = new FormData();

    setUploadedData({
      ...uploadedData,
      ...videoCategory !== uploadedData?.category_id && { category_id: videoCategory },
      ...videoCategoryName !== uploadedData?.category_name && { category_id: videoCategoryName },
      ...videoSubCategory !== uploadedData?.subcategory_id && { subcategory_id: videoSubCategory },
      ...videoPlaylists?.length !== uploadedData?.playlist_id && { playlist_id: videoPlaylists?.join(',') },
    });

    formData.append('video_id', videoID);
    formData.append('category_id', videoCategory);
    formData.append('category_name', videoCategoryName);
    formData.append('subcategory_id', videoSubCategory);
    formData.append('playlist_id', videoPlaylists?.join(','));
    formData.append('status', 0);

    setVideoCategoryPlaylistData(formData);
  };
  useEffect(() => {
    if (videoCategoryPlaylistData && currentStep === 2) { updateCategoryPlaylistContent(); }
  }, [videoCategoryPlaylistData]);
  // useEffect(() => { setVideoSubCategory(undefined); setVideoSubCategoryName(undefined); }, [videoCategory]);
  const [selectedSubCatOptionValue, selectSubCatOptionValue] = useState(videoSubCategory || '');
  const [selectedSubCatOptionName, selectSubCatOptionName] = useState(videoSubCategoryName || '');
  return (
    <>

      <DialogContent className={styles.content}>
        <ContentBox>
          {draftApiStatus === 'loading'
          && (
            <div className={styles.absol}>
              <p className={styles.marginX}>
                <CircularProgress />
              </p>
              <p className={styles.textx}>
              Updating details
              </p>
            </div>
              )}
          <ContentBoxSection
            className={styles.detailsBoxSection}
            description={uploadText?.categoriesDescription || 'This section is Required in order to proceed'}
            title={uploadText?.categories || 'Categories'}
          >
            <div className={styles.categoriesContainer}>
              <CategoryInput
                defaultValue={videoCategory}
                isRequired
                defaultName={videoCategoryName}
                selectSubCatOptionName={selectSubCatOptionName}
                selectSubCatOptionValue={selectSubCatOptionValue}
                description={uploadText?.selectCategoryDesc || 'Select a main category that your video fits into.'}
                label={uploadText?.selectCategory || 'Category'}
                onSelect={(val, name) => { setVideoCategory(val); setVideoCategoryName(name); }}
              />
              <SubCategoryInput
                defaultValue={videoSubCategory}
                defaultName={videoSubCategoryName}
                description={uploadText?.selectSubCategoryDesc || 'Better sort your video into a specific subcategory.'}
                // isDisabled={!videoCategory}
                selectSubCatOptionName={selectSubCatOptionName}
                selectedSubCatOptionName={selectedSubCatOptionName}
                selectedSubCatOptionValue={selectedSubCatOptionValue}
                selectSubCatOptionValue={selectSubCatOptionValue}
                label={uploadText?.selectSubCategory || 'Sub-Category'}
                onSelect={(val, name) => { setVideoSubCategory(val); setVideoSubCategoryName(name); }}
              />
              <PlaylistInput
                className={styles.playlist}
                defaultValues={videoPlaylists}
                defaultNames={videoPlaylistsNames}
                description={uploadText?.playlistDesc || 'Add your video to one or more playlist. Playlist’s can help your audience view special collections.'}
                label={uploadText?.playlist || 'Playlist'}
                onSelect={(val, name) => {
                  setVideoPlaylists([...val]);
                  setVideoPlaylistsNames(name);
                }}
              />
            </div>
          </ContentBoxSection>
        </ContentBox>
      </DialogContent>
      <DialogFooter twoEnd={conversionStart}>
        <div className={styles.buttonOnEnd}>
          {/* {
            conversionStart ? (
              <div className={styles.leftContainer}>
                <div className={styles.iconContainer}>
                  <CloudUploadOutlined
                    className={styles.videoUploadIcon}
                    fontSize="large"
                  />
                </div>
                <div className={cn({ [styles.progressbar]: true })}>
                  <BorderLinearProgress />
                  <div className={styles.processingText}>
                    Processing in background...
                  </div>
                </div>
              </div>
            ) : null
          } */}
          {editFlow && <EditSaveToDraftButton pubLoader={pubLoader} buttonDisabled={buttonDisabled} videoTitle={videoTitle} uploadedData={uploadedData} defaultVideoThumbnail={defaultVideoThumbnail} videoVisibility={videoVisibility} updateVisibility={updateVisibility} draftApiStatus={draftApiStatus} />}
          {!editFlow && <SaveToDraftButton buttonDisabled={buttonDisabled} videoTitle={videoTitle} uploadedData={uploadedData} defaultVideoThumbnail={defaultVideoThumbnail} videoVisibility={videoVisibility} handleSaveToDraft={handleSaveToDraft} draftApiStatus={draftApiStatus} />}
          {(draftApiStatus !== 'loading')
          && (
          <>
          <Button
            className={styles.navButton}
            isBlue
            isOutlined
            onClick={moveToPreviousStep}
          >
            {commonText?.previous || 'Previous'}
          </Button>
          <Button
            className={styles.navButton}
            isBlue
            isDisabled={false}
            isOutlined
            onClick={moveToNextStep}
          >
            {(commonText?.next || 'Next')}
          </Button>
          </>
          )}
        </div>
      </DialogFooter>

    </>
  );
}
