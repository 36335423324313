import React, { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import { AppContext } from 'Context';
import { parseNumber } from '../parse-number';
import styles from './index.module.css';
import InputGrid from '../input-grid';
import InputField from '../input-field';

function getCardNumber(value) {
  return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
}

function getExpirationDate(value) {
  if (!/^\d{0,2}\/?\d{0,2}$/.test(value)) {
    return;
  }

  let input = value;

  if (/^\d{3,}$/.test(input)) {
    input = input.match(new RegExp('.{1,2}', 'g')).join('/');
  }

  return input;
}
function CredentialField({ stripe, onCardNumberChange, onCvvNumberChange, onExpirationDateChange, setValidity, error, setError, cvc, setCvc, expiryYear, setExpiryYear, inputValue, setInputValue, expiryMonth, setExpiryMonth, cardNumberStripe, setCardNumberStripe }) {
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const { langText, cardNumber, setCardNumber, cvvNumber, setCvvNumber, expirationDate, setExpirationDate } = useContext(AppContext);
  const addCardText = langText?.settings?.billPayment?.paymentMethod?.cardDetails;

  const cardNumberIsValid = useMemo(() => (cardNumber.length === 19), [cardNumber]);
  const cvvNumberIsValid = useMemo(() => (cvvNumber.length >= 3), [cvvNumber]);
  const expirationDateIsValid = useMemo(() => (expirationDate.length === 5), [expirationDate]);

  useEffect(() => {
    setValidity(cardNumberIsValid && cvvNumberIsValid && expirationDateIsValid);
  }, [cardNumberIsValid, cvvNumberIsValid, expirationDateIsValid]);

  const handleExpiryDateChange = ({ target: { value } }) => {
    let newValue = value.replace(/\D/g, '');
    if (newValue.length > 2) {
      newValue = `${newValue.slice(0, 2)}/${newValue.slice(2)}`;
    }
    if (newValue.length > 5) {
      newValue = newValue.slice(0, 5);
    }
    const [month, year] = newValue.split('/');
    setExpiryMonth(month || '');
    setExpiryYear(year || '');
    setInputValue(newValue);
  };
  const formatCardNumber = (value) => {
    // Remove all non-digit characters
    const digits = value.replace(/\D/g, '');
    // Add space after every 4 digits
    return digits.replace(/(.{4})/g, '$1 ').trim();
  };

  const handleCardNumberChange = ({ target: { value } }) => {
    const digits = value.replace(/\D/g, '');
    if (digits.length <= 16) {
      setCardNumberStripe(formatCardNumber(digits));
    }
  };
  return (
    <>
{stripe ? (
    <form className="payment-form">
    <div className="form-group">
      <InputGrid>
        <input
          className={styles.cardNumber}
          // onChange={(e) => setCardNumberStripe(e.target.value)}
          onChange={handleCardNumberChange}
          placeholder="Card number"
          type="text"
          required
          value={cardNumberStripe}
        />
        <input
          className={styles.expirationDate}
          maxLength={5}
          type="text"
          value={inputValue}
          onChange={handleExpiryDateChange}
          placeholder="MM/YY"
          required
        />
        <input
          className={styles.cvvNumber}
          maxLength={4}
          type="text"
          value={cvc}
          onChange={(e) => setCvc(e.target.value)}
          placeholder="CVC"
          required
        />
      </InputGrid>
    </div>
    </form>
)
: (
    <InputGrid>
      <InputField
        className={styles.cardNumber}
        maxLength={19}
        onChange={(value) => {
          const parsedValue = parseNumber(value);
          setCardNumber(getCardNumber(parsedValue));
          onCardNumberChange(parsedValue);
        }}
        placeholder={addCardText?.cardNoPlaceholder || 'Card number'}
        type="text"
        value={cardNumber}
      />
      <InputField
        className={styles.expirationDate}
        maxLength={5}
        onChange={(value) => {
          const parsedValue = getExpirationDate(parseNumber(value));

          setExpirationDate(parsedValue);
          onExpirationDateChange(parsedValue);
        }}
        placeholder={addCardText?.datePlaceholder || 'MM/YY'}
        type="text"
        value={expirationDate}
      />
      <InputField
        className={styles.cvvNumber}
        maxLength={4}
        onChange={(value) => {
          const parsedValue = parseNumber(value);
          setCvvNumber(parsedValue);
          onCvvNumberChange(parsedValue);
        }}
        placeholder={addCardText?.cvvPlaceholder || 'CVV'}
        type="text"
        value={cvvNumber}
      />
    </InputGrid>
)}
    </>
  );
}

export default CredentialField;
