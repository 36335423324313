import React, { useMemo, useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import { FormGroup, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import RotatingLoader from 'Components/RotatingLoader';
import PageLayout from './components/page-layout';
import FormSection from './components/form-section';
import CredentialField from './components/credential-field';
import NameField from './components/name-field';
import PriceSectionLayout from './components/checkout-price-layout';
import logo from '../../../../../Assets/logo_round.png';
import styles from './subscribe.module.css';

function Page({ expiryYear, setInputValue, setExpiryYear, setExpiryMonth, setCardNumberStripe, setCvc, cardNumberStripe, cvc, expiryMonth, afflitedDetails, paymentChecker, loader, children, checked, setChecked, navigation, title, subTitle, handleSubmitPay, userEmail, userFirstName, userLastName, setFirstName, setLastName,
  purchasingProduct, setOpen, isDisabled, onContinue, planName, planPrice, promoCode, intervalInDays, planId, promoCodeNew, setPromoCodeNew }) {
    const [credentialIsValid, setCredentialValidity] = useState(false);
  const [nameIsValid, setNameValidity] = useState(false);
  const { allPlans, isTrail, subscriptionDetails, firstTimeOnlychecked, setFirstTimeOnlyChecked } = useContext(AppContext);
  const [paymentIsValid, setPaymentValidity] = useState(false);
  const [promoErrMsg, setPromoErrMsg] = useState('');
  const [promoLoading, setPromoLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const formIsFilled = useMemo(() => (
    credentialIsValid && nameIsValid && paymentIsValid
  ), [credentialIsValid, nameIsValid, paymentIsValid]);
  useEffect(() => {
    const checkValidity = () => cvc?.length > 0 && cardNumberStripe?.length > 0 && expiryMonth?.length > 0;
    setIsFormFilled(checkValidity());
  }, [cvc, cardNumberStripe, expiryMonth]);

  const handleCheckboxChange = (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    setIsFormFilled(newChecked);
    setExpiryMonth('');
    setExpiryYear('');
    setCardNumberStripe('');
    setCvc('');
    setInputValue('');
  };
  useEffect(() => {
  checked === true ? setIsFormFilled(true) : setIsFormFilled(false);
  }, [checked]);

  const onlyFirstTimeCheckbox = () => {
    setFirstTimeOnlyChecked(!firstTimeOnlychecked);
  };
  return (
    <PageLayout
      navigation={navigation || '/settings/billings-and-payments'}
      isFormFilled={isFormFilled}
      setOpen={setOpen}
      checked={checked}
      paymentChecker={paymentChecker}
      loader={loader}
      userEmail={userEmail}
      continueButtonText={isTrail === '1' ? 'Start Trial' : 'Purchase Now'}
      formIsFilled={formIsFilled}
      onContinue={() => {
        onContinue();
      }}
      purchasingProduct={purchasingProduct}
      subTitle=""
      title={title || 'Start sharing and preserving today'}
      promoErrMsg={promoErrMsg}
      promoCodeNew={promoCodeNew}
      promoLoading={promoLoading}
    >
      {purchasingProduct && <RotatingLoader />}
      <div style={{ display: 'flex' }}>
        <Box
          className={styles.paymentFieldInputs}
        >
          <FormSection
            title={subTitle || 'Account Information'}
          >
            {(navigation === '/settings/manage-users' || subscriptionDetails?.paymentMethods?.length > 0)
              && (
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        style={{
                          color: '#1172D2',
                        }}
                        checked={checked}
                        onChange={handleCheckboxChange}
                      />
                    )}
                    label={navigation === '/settings/manage-users' ? 'Use your account information' : 'Use your default card'}
                  />
                  {navigation === '/settings/manage-users'
                  && (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        style={{
                          color: '#1172D2',
                        }}
                        checked={firstTimeOnlychecked}
                        onChange={onlyFirstTimeCheckbox}
                      />
                    )}
                    label={navigation === '/settings/manage-users' && 'Only for first year'}
                  />
                  )}
                </FormGroup>
               )}
            <NameField
              checked={checked}
              afflitedDetails={afflitedDetails}
              userFirstName={userFirstName}
              userLastName={userLastName}
              onFirstNameChange={setFirstName}
              onLastNameChange={setLastName}
              setValidity={setNameValidity}
              isDisabled
            />
            <CredentialField
              checked={checked}
              afflitedDetails={afflitedDetails}
              userEmail={userEmail}
              setValidity={setCredentialValidity}
            />
            {checked === false || checked === undefined ? (
              <FormSection title="Payment Information">
                {children}
              </FormSection>
            ) : null}
          </FormSection>
        </Box>
        <PriceSectionLayout
          navigation={navigation}
          planName={planName}
          planPrice={allPlans?.find((plan) => plan?.id === planId)?.amount || allPlans[0]}
          planId={planId}
          promoCode={afflitedDetails?.code || afflitedDetails?.affiliateCode}
          intervalInDays={intervalInDays}
          promoErrMsg={promoErrMsg}
          setPromoErrMsg={setPromoErrMsg}
          promoCodeNew={promoCodeNew}
          setPromoCodeNew={setPromoCodeNew}
          promoLoading={promoLoading}
          setPromoLoading={setPromoLoading}
        />
      </div>
    </PageLayout>
  );
}

export default Page;
