import React, { useContext, useRef, useEffect, useState, useLayoutEffect } from 'react';
import cn from 'classnames';
import DropMe from 'Components/DropMe/DropMe';
import ListingModal from 'Components/ListingModal';
import { VideoPopupModal } from 'Components/VideoPopup';
import { ALLOWED_UPLOADS_COUNT, PLANS } from 'Commons';
import { AppContext, AuthContext } from 'Context';
import { Header } from '../header';
import { Sidebar } from '../sidebar';
import styles from './index.module.css';
import Cloud from '../../Assets/upload.gif';
import DontUpload from '../../Assets/dont.gif';

export const Page = ({
  children,
  className,
  containerClassName,
  contentClassName,
  glassCompactHeaderBG,
  id,
  isProfileHeader,
  noBottomBar,
  noDefaultBg,
  noFixedSidebar,
  noHeader,
  noRegularHeader,
  transparentCompactHeader,
  userName,
  userProfileLink,
  headerTheme,
  accessPageBackGround,
  videoBanner,
  layoutData
}) => {
  const [sidebarIsVisible, setSidebarVisibility] = useState(false);
  const { autoSignIn, setAppSnackbar, disableDrag, userDetails, subscriptionDetails, selectedFiles, MAX_VIDEOS } = useContext(AppContext);
  const revealSidebar = () => { setSidebarVisibility(true); };
  const hideSidebar = () => { setSidebarVisibility(false); };
  const [droper, setDroper] = useState(false);
  const [videoFiles, setVideoFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [uploadModalIsVisible, setUploadModalVisibility] = useState(false);
const [popUpModalVisible, setPopUpModalVisible] = useState(false);
const [crossDrag, setCrossDrag] = useState(false);
const [editLoader, setEditLoader] = useState(false);
const [overlay, setOverlay] = useState(false);
const [open, setOpen] = useState(false);
const handleOpen = () => setOpen(true);
const handleSingleDrop = () => {
  setDroper(false);
  // setCrossDrag(false);
};
const allowedExtensions = ['mp4', 'mov', 'mpg', 'm4v', 'm2ts'];

const MAX_SIZE_GB = 30;
const MAX_SIZE_BYTES = MAX_SIZE_GB * 1024 * 1024 * 1024; // Convert 2GB to bytes
const revealUploadModal = (videoFiles) => {
  const totalSize = videoFiles?.reduce((acc, file) => acc + file.size, 0);
  localStorage.setItem('opened_draft_id', null);
  autoSignIn().then((res) => {
    if (!res?.data?.subscription && res?.data?.totalContentUploaded >= ALLOWED_UPLOADS_COUNT) {
      setPopUpModalVisible(true);
      setEditLoader(false);
      setOpen(false);
    } else {
      setUploadModalVisibility(true);
      setEditLoader(false);
    }
  });
  const invalidFiles = videoFiles.filter((file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    return !allowedExtensions.includes(extension);
  });
  if (invalidFiles.length > 0) {
    setAppSnackbar({ isVisible: true, type: 'error', message: 'Invalid file format. Allowed formats are: .mp4, .mov, .mpg, .m4v, .m2ts' });
    // alert('Invalid file format. Allowed formats are: .mp4, .mov, .mpg, .m4v, .m2ts');
    return;
  }
  if (userDetails?.isSubscription === null) {
    if (videoFiles?.length <= 1 && totalSize <= MAX_SIZE_BYTES && userDetails?.totalVideoUploaded === 0) {
      handleOpen();
    } else {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'One free upload allowed' });
    }
  } else if (userDetails?.isSubscription !== null) {
    if (videoFiles?.length <= MAX_VIDEOS && totalSize <= MAX_SIZE_BYTES) {
      handleOpen();
    } else {
      const errorMessage = videoFiles?.length > MAX_VIDEOS
        ? `Maximum of ${MAX_VIDEOS} videos allowed`
        : 'Total size of videos should not exceed 2GB';
      setAppSnackbar({ isVisible: true, type: 'error', message: errorMessage });
    }
  }
};
const dragOver = (e) => {
  e.preventDefault();
  // if (disableDrag === false) {
  //   setCrossDrag(true);
  // } else {
  //   setCrossDrag(false);
  // }
  setDroper(true);
  setOverlay(true);
};
const dragLeave = (e) => {
  e.preventDefault();
  setDroper(false);
  // setOverlay(false);
};
const drop = (e) => {
  setOverlay(false);
};
const hideUploadModal = () => {
  setUploadModalVisibility(false);
};
useEffect(() => {
  // autoSignIn();
  document.addEventListener('dragover', dragOver);
  document.addEventListener('dragleave', dragLeave);
  document.addEventListener('drop', drop);
  return () => {
    document.removeEventListener('dragover', dragOver);
    document.removeEventListener('dragleave', dragLeave);
    document.removeEventListener('drop', drop);
  };
}, []);
const handleDrop = (e) => {
  setOverlay(false);
  e.preventDefault();
  const files = Array.from(e.dataTransfer.files);
  const videoFiles = files.filter((file) => file.type.startsWith('video/'));
  const imageFiles = files.filter((file) => file.type.startsWith('image/'));
  setVideoFiles(videoFiles);
  setImageFiles(imageFiles);
  revealUploadModal(videoFiles);
  setDroper(false);
};
const MultiSelect = () => {
  setOverlay(false);
  const files = Array.from(selectedFiles);
  const videoFiles = files.filter((file) => file.type.startsWith('video/'));
  const imageFiles = files.filter((file) => file.type.startsWith('image/'));
  setVideoFiles(videoFiles);
  setImageFiles(imageFiles);
  revealUploadModal(videoFiles);
  setDroper(false);
};
useEffect(() => {
  if (selectedFiles?.length > 1) {
    MultiSelect();
  }
  }, [selectedFiles]);

const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setMousePosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  // useEffect(() => {
  //   if (overlay === true) {
  //     setTimeout(() => {
  //       setOverlay(droper);
  //     }, 500);
  //   } else {
  //     setOverlay(droper);
  //   }
  // }, [droper]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  const iconStyle = {
    position: 'absolute',
    top: mousePosition.y,
    left: mousePosition.x,
    pointerEvents: 'none', // Prevents icon from blocking any interactions
    transform: 'translate(-50%, -50%)', // Center the icon at the mouse position
  };
  const hidepopModal = () => { setPopUpModalVisible(false); };
  return (
    <div
      // style={{ opacity: droper === true ? '.3' : '1' }}
      onDrop={disableDrag === true ? handleDrop : handleSingleDrop}
      onDragOver={(e) => dragOver(e)}
      onDragLeave={(e) => dragLeave(e)}
      className={cn({ [containerClassName]: containerClassName })}
    >
      {/* {disableDrag && overlay && (
        <div className={styles.iamfixed} style={{ height: '100vh', width: '100vw', zIndex: '1000', position: 'absolute', backgroundColor: '#FFFFFF30' }}>
          <img className={styles.cloud} src={Cloud} alt="" />
        </div>
      )}
      {crossDrag && (
        <div className={styles.iamfixed} style={{ height: '100vh', width: '100vw', zIndex: '1000', position: 'absolute', backgroundColor: '#FFFFFF30' }}>
          <img className={styles.cloud} src={DontUpload} alt="" />
        </div>
      )}   */}
      <Header
        glassCompactHeaderBG={glassCompactHeaderBG}
        isProfileHeader={isProfileHeader}
        noHeader={noHeader}
        noRegularHeader={noRegularHeader}
        onMenuClick={revealSidebar}
        transparentCompactHeader={transparentCompactHeader}
        userName={userName}
        userProfileLink={userProfileLink}
        headerTheme={headerTheme}
        videoBanner={videoBanner}
        layoutData={layoutData}
      />
           <VideoPopupModal isVisible={popUpModalVisible} onHide={hidepopModal} />
      <main className={cn({
      [styles.page]: true,
      [styles.pageBottom]: !accessPageBackGround,
      [styles.noDefaultBg]: noDefaultBg,
      [styles.accessPageBackGround]: accessPageBackGround,
      [styles.holdMe]: window.location.pathname === '/your-videos', // Conditionally apply styles.holdMe
      [className]: className
      })}
      >
           {/* { droper && <DropMe dragLeave={dragLeave} dragOver={dragOver} handleDrop={handleDrop} />} */}
           {open && <ListingModal setOpen={setOpen} setEditLoader={setEditLoader} videoFiles={videoFiles} setVideoFiles={setVideoFiles} />}
        {/* <div className={styles.sidebarWrapperFixed}> */}
          <Sidebar
            noBottomBar={noBottomBar}
            noFixedSidebar={noFixedSidebar}
            onClose={hideSidebar}
            sidebarIsVisible={sidebarIsVisible}
          />
        {/* </div> */}
        {/* <div className={styles.contentWrapper}> */}
          <div className={cn({ [styles.pageContent]: true, [styles.pageContentBottom]: !accessPageBackGround, [contentClassName]: contentClassName })} id={id}>
            {children}
          </div>
        {/* </div> */}
      </main>
    </div>
  );
};
