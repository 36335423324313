import React, { useState, useContext, useEffect } from 'react';
import useSWR from 'swr';
import { Modal, Snackbar } from 'Components';
import { AppContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { debounce } from 'lodash';
import { validate as isValidEmail } from 'email-validator';
import { Collapse, Fade } from '@material-ui/core';
import { Input } from '../Input';
import styles from './styles.module.css';

const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };
const transitionTimeout = 300;

const EmailEditModal = ({ isVisible, onHide: onClose, title, resetCodeLabel, newEmailLabel }) => {
  const { userDetails, setUserDetails, langText } = useContext(AppContext);
  const accountText = langText?.settings?.account;
  const commonText = langText?.common;
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [checkingEmailAvailability, setEmailAvailabilityCheckingStatus] = useState(false);
  const [emailIsAvailable, setEmailAvailability] = useState(false);
  const [resetCodeSent, setResetCodeStatus] = useState(false);
  const [cancelToken, setCancelToken] = useState(undefined);

  const [snackbar, setSnackbar] = useState(defaultSnackbar);

  const { isValidating: sendingResetCode, mutate: sendResetCode } = useSWR([endpoints.changeEmailRequest], {
    onSuccess: ({ success }) => {
      if (success) {
        setResetCodeStatus(true);
      } else {
        setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while sending Reset Code' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while sending Reset Code' });
    },
  });

  const { mutate: checkEmailAvailability } = useSWR([endpoints.signupEmail, email, cancelToken?.token], {
    fetcher: (url, inputEmail, uniqueCancelToken) => dataFetcher(url, { email: inputEmail }, uniqueCancelToken),
    onSuccess: ({ success }) => {
      setEmailAvailabilityCheckingStatus(false);

      if (success) {
        setEmailAvailability(true);
      } else {
        setEmailAvailability(false);
        setSnackbar({ isVisible: true, type: 'error', message: 'This email is already taken' });
      }
    },
    onError: () => {
      setEmailAvailabilityCheckingStatus(false);
    },
  });

  const onHide = () => {
    setEmail('');
    setResetCode('');
    setEmailAvailability(false);
    setResetCodeStatus(false);
    setEmailAvailabilityCheckingStatus(false);
    setSnackbar(defaultSnackbar);
    onClose();
  };

  const { isValidating: updatingEmail, mutate: updateEmail } = useSWR([endpoints.updateEmailAddress, email, resetCode], {
    fetcher: (url, inputEmail, inputResetCode) => dataFetcher(url, { reset_code: inputResetCode, email: inputEmail }),
    onSuccess: ({ success }) => {
      if (success) {
        setSnackbar({ isVisible: true, type: 'success', message: 'Email was updated successfully' });
        setTimeout(onHide, 1500);
        setUserDetails({ ...userDetails, email });
      } else {
        setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating email' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating email' });
    },
  });

  const emailInputHandler = debounce(({ target: { value: inputEmail } }) => {
    if (isValidEmail(inputEmail)) {
      setEmail(inputEmail);
    } else {
      setEmail('');
      setEmailAvailability(false);
    }
  }, 750, { trailing: true });

  useEffect(() => {
    if (email) {
      setEmailAvailabilityCheckingStatus(false);
      checkEmailAvailability();
    }
  }, [email, setEmailAvailabilityCheckingStatus, checkEmailAvailability]);

  useEffect(() => { if (isVisible) { sendResetCode(); } }, [isVisible, sendResetCode]);

  return (
    <>
      <Modal
        isVisible={isVisible}
        title={title}
        onHide={onHide}
        cancelButton={commonText?.cancel || 'Cancel'}
        cancelButtonClassName={styles.actionButton}
        onCancelButtonClick={onHide}
        continueButton={updatingEmail ? `${commonText?.updating || 'Updating'}...` : (commonText?.update || 'Update')}
        continueButtonClassName={styles.actionButton}
        onContinueButtonClick={updateEmail}
        continueButtonProps={{
          isDisabled: checkingEmailAvailability
            || resetCode?.length !== 5 || updatingEmail || sendingResetCode || !emailIsAvailable
        }}
      >
        <br />

        <Collapse in={resetCodeSent} timeout={transitionTimeout}>
          <Fade in={resetCodeSent} timeout={transitionTimeout}>
            <>
              <Input
                auto-complete="one-time-code"
                className={styles.inputField}
                containerClassName={styles.inputFieldContainer}
                labelClassName={styles.inputFieldLabel}
                label={resetCodeLabel}
                onInput={(e) => {
                  const { target: { value: inputResetCode } } = e;

                  setResetCode(inputResetCode);
                }}
                type="code"
              />
              <br />
              <Input
                className={styles.inputField}
                containerClassName={styles.inputFieldContainer}
                isLoading={checkingEmailAvailability}
                labelClassName={styles.inputFieldLabel}
                label={newEmailLabel}
                placeholder="example@projector.com"
                onChange={(e) => {
                  e.persist();
                  emailInputHandler(e);
                  setEmailAvailabilityCheckingStatus(false);

                  if (cancelToken) { cancelToken.cancel(); }

                  if (!e?.target?.value) {
                    setEmail('');
                    setEmailAvailability(false);
                  } else {
                    setCancelToken(AxiosCancelToken.source());
                  }
                }}
                type="email"
              />
            </>
          </Fade>
        </Collapse>

        <Collapse in={sendingResetCode} timeout={transitionTimeout}>
          <Fade in={sendingResetCode} timeout={transitionTimeout}>
            <div>{accountText?.sendingResetCode || 'Sending Reset Code'}</div>
          </Fade>
        </Collapse>
      </Modal>

      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </>
  );
};
export default EmailEditModal;
