import React from 'react';

const ThreeDotLoader = () => (
  <div className="loader">
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
  </div>
);

export default ThreeDotLoader;
