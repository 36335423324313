import React, { useState, useContext, useEffect } from 'react';
import MessageComponent from 'MessageComponent';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from 'Api';
import { FormGroup, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { AppContext, AuthContext } from 'Context';
import axios from 'axios';
import StripeProvider from './StripeProvider';
import PaymentField from '../private/settings/manage-payment-methods/AddCard/components/payment-field';
import SubscriptionPage from '../public/landing/plans/CheckoutForm/subscribe';

function MangerPaymentForUser() {
  const navigate = useNavigate();
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
      // Redirect to '/settings' when the page is reloading
      navigate('/settings/manage-users');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);
    const { apiCALL, isTrail, bigUserData, setmessaged, setmangerFunctionData,
      firstTimeOnlychecked, apiResponse, setAppSnackbar } = useContext(AppContext);
    const { getToken } = useContext(AuthContext);
    const myToken = getToken();
    const userData = apiCALL?.data?.userDetails[0] || bigUserData;
    const [email, setEmail] = useState(userData?.email);
    const [firstName, setFirstName] = useState(userData?.firstname);
    const [lastName, setLastName] = useState(userData?.lastname);
    const [checked, setChecked] = useState(false);
    const [cardNumber, setCardNumber] = useState(undefined);
    const [expirationDate, setExpirationDate] = useState();
    const [loader, setloader] = useState(false);
    const [cardNumberStripe, setCardNumberStripe] = useState('');
    const [cvvNumber, setCvvNumber] = useState(undefined);
    const [paymentIsValid, setPaymentValidity] = useState(false);
    const [paymentChecker, setPaymentChecker] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [promoCodeNew, setPromoCodeNew] = useState('');
    const [error, setError] = useState(null);
    const [cvc, setCvc] = useState('');
    const [stripeToken, setStripeToken] = useState([]);
    const spliter = expirationDate?.split('/');
    const callStripeAPI = async () => {
      const datax = new URLSearchParams();
      datax.append('card[number]', cardNumberStripe);
      datax.append('card[exp_month]', expiryMonth);
      datax.append('card[exp_year]', expiryYear);
      datax.append('card[cvc]', cvc);
      try {
        const response = await axios.post('https://api.stripe.com/v1/tokens', datax, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer pk_live_51OyTOEKaFrbWdXakXgNskBU4TmXmqTIP6thT1vf8LjiR1FADapz2jX6Fi5a1h8KpG7hCAiEK9EnlrKbH7wDVGjOs00ReGilVdx',
          },
        });
        return response.data;
      } catch (error) {
        console.error('Error:', error?.response);
        setAppSnackbar({ isVisible: true, type: 'error', message: error?.response?.data?.error?.message });
      }
    };
    const handleCheckboxChange = async () => {
      setloader(true);
      const userId = userData?.id || userData?.userId || localStorage.getItem('temp_user_id');
      if (checked === true) {
        try {
          const response = await axios.post(`${baseUrl}/subscribePlan`, {
            token: myToken,
            userId,
            plan_id: '11',
            is_free: '0',
            managerCard: '1',
            only_first_time: firstTimeOnlychecked,
            trial: isTrail === '1',
            promo: promoCodeNew
          });
          setmessaged(response?.data?.message);
          setmangerFunctionData(true);
          navigate('/settings/manage-users');
        } catch (error) {
          console.error(error.response.data);
          const errorMessage = error.response.data.message;
          setmessaged(errorMessage);
          setmangerFunctionData(true);
          // if (error?.response?.data?.success === true) {
          //   navigate('/settings/manage-users');
          // }
        } finally {
          setloader(false);
        }
      } else {
        try {
          const stripeToken1 = await callStripeAPI();
          const stripeToken2 = await callStripeAPI();
          setStripeToken([stripeToken1, stripeToken2]);
          const stripeTokenId1 = stripeToken1;
          const stripeTokenId2 = stripeToken2;
          const addPaymentResponse = await axios.post(`${baseUrl}/v2/addPaymentMethods`, {
            token: myToken,
            card_token_alternative: stripeToken1?.id,
            card_token: stripeToken2?.id,
            userId,
            managerCard: '0',
          });
          const subscribeResponse = await axios.post(`${baseUrl}/subscribePlan`, {
            token: myToken,
            userId,
            plan_id: '11',
            is_free: '0',
            managerCard: '0',
            only_first_time: firstTimeOnlychecked,
            trial: isTrail === '1',
            promo: promoCodeNew
          });
          setAppSnackbar({ isVisible: true, type: 'success', message: subscribeResponse?.data?.message });
          navigate('/settings/manage-users');
        } catch (error) {
          const errorMessage = error?.response?.data?.message || error.message;
          setmessaged(errorMessage);
          setmangerFunctionData(true);
          // if (error?.response?.data?.success === true) {
          //   navigate('/settings/manage-users');
          // }
        } finally {
          setloader(false);
        }
      }
    };

      const letCustomerPay = async (obj) => {
        try {
          const response = await axios.post(`${baseUrl}/api/sendToAffiliate`, {
            token: getToken(),
            affiliate_email: obj?.email
          });
          navigate('/settings/manage-users');
        } catch (error) {
          const responseData = error?.response?.data;
        }
      };
  return (
    <div>
    <Box bgcolor="#FAFBFC">
        <SubscriptionPage
          afflitedDetails={apiResponse?.affDetails}
          loader={loader}
          paymentChecker={paymentChecker}
          setloader={setloader}
          userEmail={email}
          userFirstName={firstName}
          userLastName={lastName}
          setFirstName={setFirstName}
          setLastName={setLastName}
          title="Add Payment Method For New User"
          subTitle="Add the payment method that will be used for this account"
          promoCodeNew={promoCodeNew}
          setPromoCodeNew={setPromoCodeNew}
          planId={11}
          checked={checked}
          setChecked={setChecked}
          navigation="/settings/manage-users"
          expiryMonth={expiryMonth}
          cardNumberStripe={cardNumberStripe}
          cvc={cvc}
          setExpiryMonth={setExpiryMonth}
          expiryYear={expiryYear}
          setExpiryYear={setExpiryYear}
          setCardNumberStripe={setCardNumberStripe}
          setCvc={setCvc}
          setInputValue={setInputValue}
        //   purchasingProduct={processing}
        //   isDisabled={userDetails.firstname !== '' && userDetails.lastName !== ''}
          onContinue={handleCheckboxChange}
        //   totalTrialDays={totalTrialDays}
        //   planId={selectedPlan}
        >
           {checked === false && (
            <div className="card">
              <StripeProvider>
                <PaymentField
                  stripe
                  error={error}
                  setError={setError}
                  cvc={cvc}
                  setCvc={setCvc}
                  expiryYear={expiryYear}
                  setExpiryYear={setExpiryYear}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  expiryMonth={expiryMonth}
                  setExpiryMonth={setExpiryMonth}
                  cardNumberStripe={cardNumberStripe}
                  setCardNumberStripe={setCardNumberStripe}
                  stripeToken={stripeToken}
                  setStripeToken={setStripeToken}
                  paymentChecker={paymentChecker}
                  onCardNumberChange={setCardNumber}
                  onCvvNumberChange={setCvvNumber}
                  onExpirationDateChange={setExpirationDate}
                  setValidity={setPaymentValidity}
                />
              </StripeProvider>
            </div>
            )}

        </SubscriptionPage>
        {/* {mangerFunctionData === true
      && <MessageComponent setmangerFunctionData={setmangerFunctionData} message={messaged || 'Another Manger already accessing this account'} />} */}
    </Box>
    </div>
  );
}

export default MangerPaymentForUser;
