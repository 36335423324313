const awsConfig = {
  region: 'us-west-2',
  accessKey: 'AKIARTGAFKVZOAJ5ZVFR',
  secretKey: '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',
};

const url = window.location.href;

const getBucketName = (userId) => {
  if (url.includes('localhost')) {
    return `projector-optimized/pre-production/${userId}/videos`;
  }
  if (url.includes('preprod.projector.app')) {
    return `projector-optimized/pre-production/${userId}/videos`;
  }
  if (url.includes('projector.app')) {
    return `projector-optimized/production/${userId}/videos`;
  }
  if (url.includes('dev.app.projectorstream.com.s3-website-us-west-2.amazonaws.com')) {
    return `projector-optimized/pre-production/${userId}/videos`;
  }
  throw new Error('Invalid URL');
};

const getBucketNamePath = (userId) => {
  if (url.includes('localhost')) {
    return `pre-production/${userId}/videos`;
  }
  if (url.includes('preprod.projector.app')) {
    return `pre-production/${userId}/videos`;
  }
  if (url.includes('projector.app')) {
    return `production/${userId}/videos`;
  }
  if (url.includes('dev.app.projectorstream.com.s3-website-us-west-2.amazonaws.com')) {
    return `pre-production/${userId}/videos`;
  }
  throw new Error('Invalid URL');
};

const generateS3Url = () => {
  let bucketName = '';
  if (url.includes('localhost')) {
    bucketName = 'projector-optimized';
  }
  if (url.includes('preprod.projector.app')) {
    bucketName = 'projector-optimized';
  }
  if (url.includes('projector.app')) {
    bucketName = 'projector-optimized';
  }
  if (url.includes('dev.app.projectorstream.com.s3-website-us-west-2.amazonaws.com')) {
    bucketName = 'projector-optimized';
  }
  const { region } = awsConfig;
  const s3Url = `https://${bucketName}.s3.${region}.amazonaws.com/`;
  return s3Url;
};

export { awsConfig, getBucketName, generateS3Url, getBucketNamePath };
